.templates-page {
    margin-right: 5%;
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
    max-width: 100%; 
} 

.template-content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.template-list li {
    display: flex;
    gap: 10px;
}

ul {
    list-style: none; /* Optional: Remove default bullet points */
    padding: 0;
    margin: 0;
  }
  

.template-list li:nth-child(odd) {
    background-color: white;
  }
  
.template-list li:nth-child(even) {
    background-color: lightgrey;
  }
  
.template-content label {
display: flex;
flex-direction: column; /* Stack label and input vertically */
margin-bottom: 15px;
}

.template-content label input {
margin-top: 5px; /* Add space between label and input */
padding: 5px;
border: 1px solid #ccc;
border-radius: 4px;
}