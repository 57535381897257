.automatic-page {
    margin-right: 20%;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 100px 1fr;
    gap: 40px;
    max-width: 100%;
}

.automatic-content {
    grid-column: 2;
    display: flex;
    height: auto;
}

#automatic-upload-form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* 3 columns for form fields */
    grid-template-rows: auto auto;  /* Adjust number of rows if needed */
    gap: 20px;
}

.auto-background-upload {
    grid-row: 2;
}

.list-preview {
    grid-row: 2;
}

.auto-create-button {
    grid-row: 3;
    grid-column: 2;
}

.image-upload{
    flex-basis: 15%; /* Set width to 25% */
}

.auto-image-preview {
  width: 100%;
  max-width: fit-content;
  height: auto;
  max-height: 325px;
  margin-top: 20px;
  object-fit: cover; /* Ensures the image fits within the box without distortion */
  border: 2px solid #ddd; /* Light border around the image */
  border-radius: 8px; /* Rounded corners */
  margin-top: 10px; /* Spacing from the upload button */
  display: block; /* Ensures the image is displayed as a block */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for aesthetics */
}

.scrollable-container {
    height: 400px;
    overflow: auto;
    border: 1px solid #ccc;
    margin-top: 10px;
  }
  
  .scrollable-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .scrollable-container table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: left;
  }
  
  .scrollable-container table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .placeholder-box {
    padding: 20px;
    border: 1px dashed #ccc;
    text-align: center;
    color: #888;
  }
  