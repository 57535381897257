.sidebar {
    background-color: #f0f0f0;
    height: 100vh;
    padding: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    margin-top: 10px;
}

.sidebar button {
    width: 100%;
}
